<template>
  <div class="pa-4">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="6">
      <div>
          <v-select
            v-model="selectedTest"
            :items="allTests"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="name"
            return-object
            outlined
            required
            label="Choose the type of test(s) you will run"
          />
      </div>
      <div>
          <v-text-field
            v-model="selectedBranch"
            outlined
            required
            label="Type your branch name"
          />
      </div>
      <div>
        <v-select
          v-model="selectedJobDefinitions"
          :items="allJobDefinitions"
          :loading="loading.jobsDefinitions"
          :menu-props="{ bottom: true, offsetY: true }"
          item-text="name"
          return-object
          multiple
          outlined
          required
          label="Select platform for test(s)"
        />
      </div>
      <div>
        <v-btn
          color="primary"
          :disabled="isCreateTestsButtonDisabled"
          :loading="loading.launchedJobs"
          depressed
          block
          @click="createJob"
        >
          Launch tests
        </v-btn>
      </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LaunchTests',
  props: {
    projectId: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    loading: {
      jobsDefinitions: false,
      launchedJobs: false
    },
    yamatoBranch: 'unity-metrics-launcher',
    selectedBranch: 'trunk',
    allTests: [{ name: 'Build Size', id: '1476' }], // More tests will be added in future, we will replace this by adding database table for all metrics
    selectedTest: { name: 'Build Size', id: '1476' },
    allJobDefinitions: [],
    selectedJobDefinitions: []
  }),
  computed: {
    ...mapState('user', ['load', 'user']),
    user_email () {
      return this._.get(this.user, ['email'], null)
    },
    isCreateTestsButtonDisabled () {
      return !(this.selectedJobDefinitions.length && this.selectedBranch && this.selectedTest) // check if values are filled out
    }
  },
  methods: {
    async createJob () {
      this.loading.launchedJobs = true
      try {
        for (const job of this.selectedJobDefinitions) {
          const options = {
            projectId: this.selectedTest.id,
            branch: this.yamatoBranch,
            jobDefinition: job.filename,
            environmentVariables: [
              { key: 'UNITY_VERSION', value: this.selectedBranch },
              { key: 'TEST_OWNER', value: this.user_email }
            ]
          }
          const { data: response } = await this.$api.post(
            '/yamato/create-job',
            options
          )
          if (!response.ok) throw new Error(response.message)
        }
        this.$snackbar({
          content: 'All selected tests have been launched! You will be notified in Slack with results once test finishes',
          color: 'green',
          hideActions: true,
          iconClose: true,
          timeout: 8000,
          multiLine: true,
          transition: 'slide-y-reverse-transition'
        })
      } catch (error) {
        console.error(error)
        this.$snackbar({
          content: this._.get(error, ['response', 'data', 'message'], 'Launch failed: ' + error.message),
          color: 'red',
          hideActions: true,
          iconClose: true,
          timeout: 4000,
          transition: 'slide-y-reverse-transition'
        })
      }
      this.loading.launchedJobs = false
    },
    async yamatoGetJobDefinitions () {
      this.loading.jobsDefinitions = true
      try {
        const { data: response } = await this.$api.get(
          '/yamato/get-job-definitions',
          {
            params: {
              projectId: this.selectedTest.id,
              branch: 'unity-metrics-launcher'
            }
          }
        )
        if (!response.ok) throw new Error(response.message)
        this.allJobDefinitions = this._.get(response, "data['.yamato/all-by-target-platform.yml']", [])
      } catch (error) {
        this.handleError(error)
      }
      this.loading.jobsDefinitions = false
    }
  },
  async mounted () {
    await this.yamatoGetJobDefinitions()
  }
}
</script>
