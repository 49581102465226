<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'App',
  watch: {
    'authState.isAuthenticated': function (value) {
      this.handleUserData(value)
    }
  },
  methods: {
    ...mapActions('user', ['LOGIN', 'LOGOUT']),
    async handleUserData (value) {
      if (value) {
        const user = await this.$auth.getUser()
        const { data: { data: isAdmin } } = await this.$api.get('/admin')
        this.LOGIN({ user, isAdmin })
      } else {
        this.LOGOUT()
      }
    }
  },
  mounted () {
    this.handleUserData(this.authState.isAuthenticated)
  }
}
</script>
