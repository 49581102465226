<template>
  <v-app-bar
    app
    color="primary"
    flat
    dark
  >
    <div class="d-flex align-center">
      <v-icon
        class="mr-3"
        large
      >
        mdi-unity
      </v-icon>
      <span class="title ">
        Unity Metrics
      </span>
    </div>
    <v-spacer />
    <v-menu
      v-if="authState.isAuthenticated"
      offset-y
    >
      <template #activator="{ on, attrs }">
          <v-btn
            class="ma-2"
            outlined
            color="white"
            :disabled="!name"
            :loading="!name"
            depressed
            light
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              left
              dark
            >
              mdi-menu-down
            </v-icon>
            {{ name }}
          </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="white"
              :disabled="!name"
              :loading="!name"
              to="/launch-tests"
              depressed
              light
            >
              LAUNCH TESTS
            </v-btn>
          <v-btn
            class="ma-2"
            outlined
            color="white"
            :disabled="!name"
            :loading="!name"
            to="/"
            depressed
            light
          >
            VIEW RESULTS
          </v-btn>
      </template>
      <v-list class="pa-0 ma-0">
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="index"
          @click="item.action"
        >
          <v-list-item-icon>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppBar',
  data: function () {
    return {
      menuItems: [
        {
          name: 'Sign out',
          icon: 'mdi-logout',
          action: this.logout
        }
      ]
    }
  },
  computed: {
    ...mapState('user', ['loading', 'user']),
    name () {
      return this._.get(this.user, ['name'], null)
    }
  },
  methods: {
    async logout () {
      await this.$auth.signOut()
    }
  }
}
</script>

<style scoped>
a {
  color: white;
  text-decoration: none; /* no underline */
}
#app > div.v-application--wrap > main > div > div > header > div > a {
  padding: 0 20px;
  margin: 10px;
  width: 180px;
}
</style>
