<template>
  <div class="pa-4">
    <v-row>
      <v-col class="col-3">
        <v-row class="d-block pa-2">
          <div>
            <v-combobox
              v-model="selectedYamatoBranch"
              :disabled="loading.allYamatoBranches"
              :loading="loading.allYamatoBranches"
              :items="allYamatoBranches"
              :request-object="false"
              item-text="branchName"
              item-value="branchName"
              outlined
              required
              label="Yamato Branch"
            />
          </div>
          <div>
            <v-select
              v-model="selectedStatuses"
              label="Yamato Statuses"
              class=""
              :items="allStatuses"
              deletable-chips
              small-chips
              multiple
              outlined
            />
          </div>
          <div>
            <v-btn
              class="mb-2"
              color="primary"
              :disabled="loading.jobDefinitions || !selectedYamatoBranch"
              :loading="loading.jobDefinitions"
              depressed
              block
              @click="yamatoGetJobDefinitions"
            >
              Get Job Definitions
            </v-btn>
            <v-btn
              class="mb-2"
              color="primary"
              :disabled="loading.jobs || !selectedYamatoBranch"
              :loading="loading.jobs"
              depressed
              block
              @click="yamatoGetJobs"
            >
              Get Jobs
            </v-btn>
          </div>
        </v-row>
        <v-row class="d-block pa-2">
          <v-btn
            class="mb-4"
            color="success"
            depressed
            block
            @click="environmentVariables.push({ key: '', value: '' })"
          >
            Add variable
          </v-btn>
          <div
            v-for="(variable, index) of environmentVariables"
            :key="index"
            class="d-flex align-center"
          >
            <v-text-field
              v-model.trim="variable.key"
              class="mr-2"
              label="KEY"
              hide-details
              clearable
              outlined
              dense
            />
            <v-text-field
              v-model.trim="variable.value"
              label="VALUE"
              hide-details
              clearable
              outlined
              dense
            />
            <v-btn
              color="error"
              icon
              @click="environmentVariables.splice(index, 1)"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-col>
      <v-col class="col-2">
        <div>
          <v-combobox
            v-model="selectedBranch"
            :disabled="loading.allBranches"
            :loading="loading.allBranches"
            :items="allBranches"
            outlined
            required
            label="Branch"
          />
        </div>
        <div>
          <v-text-field
            v-model.number="selectedLimit"
            type="number"
            label="Limit"
            outlined
            required
            number
          />
        </div>
        <div>
          <v-btn
            color="primary"
            :disabled="loading.data"
            :loading="loading.data"
            depressed
            block
            @click="getChangelog"
          >
            Get Changelog
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <v-expansion-panels v-if="data">
          <v-expansion-panel
            v-for="(report, date) in data"
            :key="date"
          >
            <v-expansion-panel-header>
              {{ date }} ({{ report.changelog.length }})
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-sheet
                v-for="(changeset, changesetIndex) of report.changelog.filter((changeset, changesetIndex) => changesetIndex === 0)"
                :key="changeset.id"
                class="pa-1 mb-1"
                outlined
              >
                <div>
                  <div
                    :class="{ 'font-weight-bold': changesetIndex === 0 }"
                  >
                    {{ changeset.id }}
                  </div>
                  <div>
                    <div
                      v-for="(jobDefinitions, jobDefinitionFilename) of allJobDefinitions"
                      :key="jobDefinitionFilename"
                    >
                      {{ jobDefinitionFilename }}
                      <div
                        v-for="(jobDefinition, jobDefinitionIndex) of jobDefinitions"
                        :key="`run-${jobDefinition.filename}-${jobDefinitionIndex}`"
                      >
                        <create-job-button
                          :class="{ 'mr-1': jobDefinitionIndex !== allJobDefinitions.length - 1 }"
                          :project-id="projectId"
                          :branch="selectedYamatoBranch"
                          :job-definition="jobDefinition"
                          :unity-version="changeset.id"
                          :environment-variables="environmentVariablesParsed(changeset.id)"
                          @success="yamatoGetJobs"
                        />
                      </div>
                    </div>
                    <div>
                      <v-sheet
                        v-for="job of jobsByChangeset[changeset.id.substr(0, 12)] || []"
                        :key="job.id"
                      >
                        <v-btn
                          :color="job.color"
                          :href="job.url"
                          target="_blank"
                          depressed
                          :outlined="false"
                          :dark="true"
                          x-small
                        >
                          {{ `${job.id} - ${job.projectName}/${job.name}` }}
                        </v-btn>
                        <v-icon
                          :color="job.color"
                        >
                          mdi-circle-medium
                        </v-icon>
                        <span class="text-caption">
                          {{ job.status }}
                        </span>
                      </v-sheet>
                    </div>
                  </div>
                </div>
              </v-sheet>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row
      v-if="false"
      dense
    >
      <v-card>
        <v-card-text>
          <json-viewer :value="jobsByChangeset" />
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import CreateJobButton from './components/CreateJobButton'
export default {
  components: { CreateJobButton },
  name: 'CustomOverview',
  props: {
    projectId: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    loading: {
      data: false,
      allBranches: false,
      allYamatoBranches: false,
      projects: false,
      jobDefinitions: false,
      jobs: false
    },
    data: null,
    allStatuses: ['new', 'readyToStart', 'waitingForDependency', 'waitingForAgent', 'distributing', 'inProgress', 'success', 'dependencyFailed', 'failed', 'cancelled', 'disrupted'],
    selectedStatuses: ['new', 'readyToStart', 'waitingForDependency', 'waitingForAgent', 'distributing', 'inProgress'],
    allBranches: [],
    allYamatoBranches: [],
    allJobDefinitions: [],
    jobs: [],
    next: null,
    selectedYamatoBranch: 'master',
    selectedBranch: 'trunk',
    selectedLimit: 1000,
    environmentVariables: [
      {
        key: 'UNITY_VERSION',
        value: 'INHERIT'
      }
    ]
  }),
  computed: {
    jobsByChangeset () {
      return this._.groupBy(
        this.jobs,
        (job) => {
          const revision = this._(job.additionalResults).map(addRes => this._.get(JSON.parse(addRes.data), ['revision'], null)).first()
          const unityVersion = this._.find(job.environmentVariables, { key: 'UNITY_VERSION' })
          const hasDefinedRevision = revision || unityVersion?.value
          return hasDefinedRevision ? hasDefinedRevision.substr(0, 12) : null
        }
      )
    },
    environmentVariablesParsed () {
      return (inheritedValue) => {
        return this._.cloneDeep(this.environmentVariables)
          .filter(variable => variable.key && variable.value)
          .map(
            (variable) => {
              if (variable.key === 'UNITY_VERSION' && variable.value === 'INHERIT') variable.value = inheritedValue
              return variable
            }
          )
      }
    }
  },
  methods: {
    handleError (error) {
      console.error(error)
      this.$snackbar({
        content: this._.get(error, ['response', 'data', 'message'], error.message),
        color: 'red',
        hideActions: true,
        iconClose: true,
        transition: 'slide-y-reverse-transition'
      })
    },
    calculateJobs (changelog) {
      return changelog.map((changeset) => (this.jobsByChangeset[changeset.id.substr(0, 12)] || []).length).reduce((a, b) => a + b, 0)
    },
    parseYamatoStatus (job) {
      const unityVersion = this._.find(job.environmentVariables, { key: 'UNITY_VERSION' })
      const colors = {
        new: 'grey',
        readyToStart: 'grey',
        waitingForDependency: 'purple',
        waitingForAgent: 'purple',
        distributing: 'purple',
        inProgress: 'blue',
        success: 'green',
        dependencyFailed: 'red',
        failed: 'red',
        cancelled: 'yellow',
        disrupted: 'grey'
      }
      return {
        id: parseInt(job.id),
        projectName: job.jobDefinitionFileName.match(/\.yamato\/(.+)\.yml/)[1],
        name: job.jobDefinitionName,
        status: job.status,
        color: colors[job.status],
        unityVersion: unityVersion?.value,
        environmentVariables: job.environmentVariables,
        additionalResults: job.additionalResults,
        branch: job.source.reference,
        url: `https://unity-ci.cds.internal.unity3d.com/job/${job.id}/logs`
      }
    },
    async getAllBranches () {
      this.loading.allBranches = true
      try {
        const { data: response } = await this.$api.get(
          '/branches',
          {
            params: {
              only_tracked: true
            }
          }
        )
        this.allBranches = response.data
      } catch (error) {
        this.handleError(error)
      }
      this.loading.allBranches = false
    },
    async getChangelog () {
      this.loading.data = true
      try {
        const { data: response } = await this.$api.get(
          '/changelog',
          {
            params: {
              branch: this.selectedBranch,
              limit: this.selectedLimit
            }
          }
        )
        this.data = response.data
      } catch (error) {
        this.handleError(error)
      }
      this.loading.data = false
    },
    async yamatoGetBranches () {
      this.loading.allYamatoBranches = true
      try {
        const { data: response } = await this.$api.get(
          '/yamato/get-branches',
          {
            params: {
              projectId: this.projectId
            }
          }
        )
        if (!response.ok) throw new Error(response.message)
        this.allYamatoBranches = this._.get(response, ['data'], [])
      } catch (error) {
        this.handleError(error)
      }
      this.loading.allYamatoBranches = false
    },
    async yamatoGetJobDefinitions () {
      this.loading.jobDefinitions = true
      try {
        const { data: response } = await this.$api.get(
          '/yamato/get-job-definitions',
          {
            params: {
              projectId: this.projectId,
              branch: this.selectedYamatoBranch
            }
          }
        )
        if (!response.ok) throw new Error(response.message)
        this.allJobDefinitions = this._.get(response, ['data'], [])
      } catch (error) {
        this.handleError(error)
      }
      this.loading.jobDefinitions = false
    },
    async yamatoGetJobs () {
      this.loading.jobs = true
      try {
        const { data: response } = await this.$api.get(
          '/yamato/get-jobs',
          {
            params: {
              first: 100000,
              projectId: this.projectId,
              branch: this.selectedYamatoBranch,
              status: this.selectedStatuses,
              trigger: 'MANUAL'
            }
          }
        )
        if (!response.ok) throw new Error(response.message)
        this.jobs = this._.get(response, ['data'], []).map(this.parseYamatoStatus)
      } catch (error) {
        this.handleError(error)
      }
      this.loading.jobs = false
    }
  },
  async mounted () {
    await this.getAllBranches()
    await this.yamatoGetBranches()
    await this.yamatoGetJobDefinitions()
    await this.yamatoGetJobs()
  }
}
</script>
