<template>
  <v-btn
    v-bind="$props"
    color="error"
    :disabled="loading"
    :loading="loading"
    depressed
    x-small
    @click="createJob"
  >
    {{ jobDefinition.name }}
  </v-btn>
</template>

<script>
export default {
  name: 'CreateJobButton',
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    branch: {
      type: String,
      required: true
    },
    jobDefinition: {
      type: Object,
      required: true
    },
    unityVersion: {
      type: String,
      required: true
    },
    environmentVariables: {
      type: Array,
      default: () => ([{ key: 'UNITY_VERSION', value: this.unityVersion }])
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async createJob () {
      this.loading = true
      try {
        const options = {
          projectId: this.projectId,
          branch: this.branch,
          jobDefinition: this.jobDefinition.filename,
          environmentVariables: this.environmentVariables
        }
        const { data: response } = await this.$api.post(
          '/yamato/create-job',
          options
        )
        if (!response.ok) throw new Error(response.message)
        this.$emit('success')
      } catch (error) {
        console.error(error)
        this.$snackbar({
          content: this._.get(error, ['response', 'data', 'message'], error.message),
          color: 'red',
          hideActions: true,
          iconClose: true,
          transition: 'slide-y-reverse-transition'
        })
      }
      this.loading = false
    }
  }
}
</script>
