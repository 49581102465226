import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/MainView.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Browse',
        component: () => import('../views/MainView/Browse.vue')
      },
      {
        path: '/launch-tests',
        name: 'LaunchTests',
        component: () => import('../views/MainView/LaunchTests.vue')
      },
      {
        path: '/custom-overview/:projectId',
        name: 'CustomOverview',
        meta: { requiresAdmin: true },
        component: () => import('../views/MainView/CustomOverview.vue'),
        props: true
      }
    ]
  },
  {
    path: '/login',
    component: () => import('../views/LoginView.vue'),
    beforeEnter: async (from, to, next) => {
      const isAuthenticated = await Vue.prototype.$auth.isAuthenticated()
      if (isAuthenticated) {
        return next('/')
      } else {
        return next()
      }
    }
  },
  {
    path: '/callback',
    component: () => import('../views/LoginCallback.vue')
  },
  {
    path: '/error',
    component: () => import('../views/ErrorView.vue')
  },
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      return next({ path: '/error', query: { message: 'Page not found' } })
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const onAuthRequired = async (from, to, next) => {
  if (from.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = await Vue.prototype.$auth.isAuthenticated()
    if (!isAuthenticated) {
      return next({
        path: '/login',
        query: { redirect: from.fullPath }
      })
    }
  }
  if (from.matched.some(record => record.meta.requiresAdmin)) {
    const { data: response } = await Vue.prototype.$api.get('/admin')
    if (!response.data) {
      return next({ path: '/error', query: { message: 'You are not an admin' } })
    }
  }
  return next()
}

router.beforeEach(onAuthRequired)

export default router
